import { createAsyncThunk, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";

import { TumekeJSModule } from "@kernel";
import { Config } from "@kernel-config";
import { deleteNotification as deleteNotificationApi } from "@kernel-helpers/DatabaseHelpers";
import { asyncGetIdToken } from "@tumeke/tumekejs";
import { ReduxState } from "@kernel-store/reducers";
import {
  generateReportSuccess,
  setWebsocketFailed,
} from "@kernel-store/videos/slice";
import { addNotificationRecord } from "@kernel-store/notifications/slice";

const loadingData = {
  listenerActive: false,
};

const websocket: { channel: Socket | null } = {
  channel: null,
};

const registerNotificationsListener = (
  { userId }: { userId: number },
  {
    dispatch,
  }: {
    dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
  },
): (() => void) => {
  const handleNewNotification = (notifDocs: any[]) => {
    for (let i = 0; i < notifDocs.length; i += 1) {
      const notification = {
        ...notifDocs[i],
        ...notifDocs[i].data,
      };
      dispatch(addNotificationRecord({ notification, key: notifDocs[i].id }));
    }
  };
  if (websocket.channel) {
    if (websocket.channel.connected) {
      asyncGetIdToken("web").then((authToken) => {
        websocket.channel?.emit("subscribeToNotifications", {
          senderUserId: userId,
          cognitoAuthToken: authToken,
        });
      });

      const unsubscribe = () => {
        websocket.channel?.close();
        websocket.channel = null;
      };
      return unsubscribe;
    }
    websocket.channel?.close();
    websocket.channel = null;
  }
  websocket.channel = io(`${Config.TUMEKE_SERVER_WEBSOCKET_API}/notifications`);

  websocket.channel.on("connect", async () => {
    const authToken: string = await asyncGetIdToken("web");
    websocket.channel?.emit("subscribeToNotifications", {
      senderUserId: userId,
      cognitoAuthToken: authToken,
    });
  });

  websocket.channel.on("disconnect", () => {
    console.log("Notification channel closed");
  });

  websocket.channel.on("errorToClient", (data: any) => {
    if (data.message === "Bad auth") {
      websocket.channel?.close();
      websocket.channel = null;
      return;
    }
    dispatch(setWebsocketFailed({ websocketFailed: true }));
  });
  websocket.channel.on("error", () => {
    dispatch(setWebsocketFailed({ websocketFailed: true }));
  });

  websocket.channel.on("allNotificationsToClient", (data: any) => {
    handleNewNotification(data.notifications);
  });

  websocket.channel.on("notificationToClient", (data: any) => {
    handleNewNotification([data.notification]);
  });

  websocket.channel.on("reportUrlToClient", (response: any) => {
    if (
      response.requestId &&
      (TumekeJSModule.getSession("notificationRequestId") as string) !==
        response.requestId
    ) {
      return;
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = response.url;
    downloadLink.setAttribute("target", "downloadIframe");
    downloadLink.setAttribute("download", "report.pdf");
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    dispatch(generateReportSuccess());
  });

  const unsubscribe = () => {
    websocket.channel?.close();
    websocket.channel = null;
  };
  return unsubscribe;
};

export const startNotificationListener = createAsyncThunk<
  void,
  undefined,
  { state: ReduxState }
>(
  "notifications/startNotificationListener",
  async (_, { getState, dispatch }) => {
    if (loadingData.listenerActive) {
      return;
    }
    loadingData.listenerActive = true;
    console.log("Starting notification listener thunk");
    const state = getState();
    const userId = state.authUser.user.id;
    registerNotificationsListener({ userId }, { dispatch });
  },
);

export const removeNotificationListener = createAsyncThunk<
  void,
  undefined,
  { state: ReduxState }
>("notifications/removeNotificationListener", async () => {});

export const deleteNotificationRecord = createAsyncThunk<
  { notificationId: number },
  { notificationId: number },
  { state: ReduxState }
>("notifications/deleteNotificationRecord", async ({ notificationId }) => {
  await deleteNotificationApi(notificationId);
  return { notificationId };
});
