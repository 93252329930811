import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { Assessments } from "@kernel-constants/maps";
import { getAssessmentScore } from "@kernel-helpers/assessments";
import {
  getImprovementsRequest,
  saveImprovement,
  deleteImprovementRequest,
  setExternalImprovements,
} from "@kernel-store/improvements/thunk";

export type TImpovementPageMetadata = {
  currentPage: number;
  pageSize: number;
};

export type ImprovementsReduxState = {
  list: any;
  count: number;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  pageMetadata: TImpovementPageMetadata;
};

export const improvementsInitialState: ImprovementsReduxState = {
  list: {},
  count: 0,
  loading: false,
  pageMetadata: {
    currentPage: 1,
    pageSize: 8,
  },
  saving: false,
  deleting: false,
};

const improvementsSlice = createSlice({
  name: "improvements",
  initialState: improvementsInitialState,
  reducers: {
    updateImprovement: (
      state,
      action: PayloadAction<{ improvementData: any }>,
    ) => {
      const { improvementData } = action.payload;
      const key = String(improvementData.id);

      let video: any = {};
      let videoAfter: any = {};
      let assessment: any = state.list[key] ? state.list[key].assessment : {};
      let assessmentAfter: any = {};
      (improvementData?.videos_before || []).some((vid: any) =>
        (vid.tasks || []).some((task: any) =>
          (task.assessments || []).some((taskAssessment: any) => {
            video = vid;
            assessment = {
              ...assessment,
              ...taskAssessment,
            };
            return true;
          }),
        ),
      );
      (improvementData?.videos_after || []).some((vid: any) =>
        (vid.tasks || []).some((task: any) =>
          (task.assessments || []).some((taskAssessment: any) => {
            videoAfter = vid;
            assessmentAfter = taskAssessment;
            return true;
          }),
        ),
      );
      const primaryImprovementAssessment =
        improvementData.improvement_assessments.find(
          (improvementAssessment: any) => improvementAssessment.is_primary,
        );

      let score = -1;
      const assessmentResult = assessment?.posture_assessments
        ? assessment?.posture_assessments[0].riskAssessment?.assessmentResult
        : {};
      if (typeof assessment?.data?.score !== "undefined") {
        score = assessment?.data?.score;
      } else if (typeof assessmentResult?.Score !== "undefined") {
        score = assessmentResult.Score;
      } else if (assessmentResult?.OverallScore !== "undefined") {
        score = assessmentResult.OverallScore;
      } else if (assessmentResult?.li !== "undefined") {
        score = assessmentResult.li;
      } else if (assessmentResult?.mal !== "undefined") {
        score = assessmentResult.mal;
      }
      const currentScore = getAssessmentScore(
        assessment?.data?.assessmentType,
        score,
      );
      const improvedScore = getAssessmentScore(
        primaryImprovementAssessment?.data?.assessmentType,
        primaryImprovementAssessment?.data?.score,
      );
      let componentName = "";
      if (
        [Assessments.RULA, Assessments.REBA].includes(
          primaryImprovementAssessment?.data?.assessmentType,
        )
      ) {
        componentName = "rulareba";
      }
      if (
        primaryImprovementAssessment?.data?.assessmentType ===
        Assessments.HAND_STRAIN
      ) {
        componentName = "handstrain";
      }

      const improvement = {
        id: improvementData.id,
        name: improvementData.name,
        assessmentType: primaryImprovementAssessment?.data?.assessmentType,
        dueDate: improvementData.due_date,
        metadata: improvementData.metadata,
        status: improvementData.status,
        owner: improvementData.owner,
        performer: improvementData.performer,
        recommendations: improvementData.recommendations,
        roi: improvementData.roi,
        thumbnailLoc:
          state.list[key]?.thumbnailLoc || improvementData.thumbnail_loc || "",
        createdAt: improvementData.created_at,
        updatedAt: improvementData.updated_at,
        visible: true,
        video,
        assessment,
        improvedAssessments: improvementData.improvement_assessments,
        videoAfter,
        assessmentAfter,
        currentScore,
        improvedScore,
        componentName,
      };

      state.list[key] = improvement;
    },
    updateImprovedAssessment: (
      state,
      action: PayloadAction<{
        improvementId: number;
        improvementAssessmentId?: number;
        isPrimary: boolean;
      }>,
    ) => {
      const { improvementId, improvementAssessmentId, isPrimary } =
        action.payload;
      const key = String(improvementId);
      const improvement = state.list[key];
      if (!improvement) {
        return;
      }
      const newImprovedAssessments = [...improvement.improvedAssessments];
      if (improvementAssessmentId === undefined) {
        newImprovedAssessments.push({
          ...newImprovedAssessments[0],
          is_primary: isPrimary,
        });
        return;
      }
      const improvedAssessmentIndex = improvement.improvedAssessments.find(
        (improvedAssessment: any) =>
          improvedAssessment.improvement_assessment.id ===
          improvementAssessmentId,
      );
      if (improvedAssessmentIndex === -1) {
        return;
      }
      newImprovedAssessments[improvedAssessmentIndex].is_primary = isPrimary;
      state.list[key].improvedAssessments = newImprovedAssessments;
    },
    getImprovementThumbnailSuccess: (
      state,
      action: PayloadAction<{ videoId: string; url: string }>,
    ) => {
      const { videoId, url } = action.payload;

      Object.keys(state.list).forEach((improvementId) => {
        if (state.list[improvementId].video?.id === videoId) {
          state.list[improvementId].thumbnailLoc = url;
        }
      });
    },
    setImprovementPageMetadata: (
      state,
      action: PayloadAction<Partial<TImpovementPageMetadata>>,
    ) => {
      const newPayload = { ...action.payload };
      const pageMetadataKeys = Object.keys(
        newPayload,
      ) as (keyof TImpovementPageMetadata)[];
      pageMetadataKeys.forEach((key) => {
        if (newPayload[key] === undefined) {
          delete newPayload[key];
        }
      });

      state.pageMetadata = {
        ...state.pageMetadata,
        ...newPayload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImprovementsRequest.pending, (state, action) => {
      if (!action.meta.arg.improvementId) {
        state.loading = true;
        Object.keys(state.list).forEach((key) => {
          state.list[key].visible = false;
        });
      }
    });
    builder.addCase(getImprovementsRequest.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.loading = false;
    });
    builder.addCase(getImprovementsRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(saveImprovement.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(saveImprovement.fulfilled, (state) => {
      state.saving = false;
    });
    builder.addCase(saveImprovement.rejected, (state) => {
      state.saving = false;
    });
    builder.addCase(deleteImprovementRequest.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteImprovementRequest.fulfilled, (state, action) => {
      const { improvementIds } = action.payload;
      improvementIds.forEach((improvementId) => {
        delete state.list[improvementId];
      });
      state.deleting = false;
    });
    builder.addCase(deleteImprovementRequest.rejected, (state) => {
      state.deleting = false;
    });
    builder.addCase(setExternalImprovements.fulfilled, (state, action) => {
      state.count = action.payload.count;
      state.loading = false;
    });
  },
});

export const {
  updateImprovement,
  getImprovementThumbnailSuccess,
  setImprovementPageMetadata,
} = improvementsSlice.actions;

export default improvementsSlice.reducer;
