import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getDashboardDataRequest,
  getExternalDashboardDataRequest,
  getDashboardFiltersRequest,
  setDashboardFilterField,
  setDashboardFilterFields,
  clearDashboardFilters,
  setDashboardOnboardingFinished,
  saveDashboardFilter,
  deleteDashboardFilter,
  getDashboardDataExternal,
  exportBulkCSVRequest,
  getDashboardBodyPartDataRequest,
} from "@kernel-store/dashboard/thunk";
import { sortMetadataArray } from "@kernel-store/auth/slice";

export type TDashboardFilter = {
  assessment_types: any;
  assessors: any;
  start_date: any;
  end_date: any;
  metadata_array: any;
  groups: any;
  subject1_risk_levels: any;
  status?: "todo" | "inprogress" | "done" | null;
};

export const EMPTY_FILTER: TDashboardFilter = {
  assessment_types: [],
  assessors: [],
  start_date: null,
  end_date: null,
  metadata_array: [],
  groups: [],
  subject1_risk_levels: [],
};

export type TSavedDashboardFilter = {
  name: string;
  filters: TDashboardFilter;
};

export type DashboardReduxState = {
  dashboardData: any;
  dashboardBodyData: any;
  dashboardInitialized: boolean;
  dashboardDataLoading: boolean;
  dashboardBodyDataLoading: boolean;
  dashboardExternalDataLoading: boolean;
  dashboardFiltersLoading: boolean;
  dashboardDataShortKey: string;
  dashboardFilters: any;
  dashboardDataDirty: boolean;
  dashboardFieldParam: string | null;
  dashboardPerformanceParam: string | null;
  dashboardGranularity: string | null;
  dashboardAggType: string | null;
  dashboardAssessmentTypesParam: number[] | null;
  dashboardBodyPartViewParam: number | string | null;
  dashboardBodyPartCalculateParam: number | string | null;
  filterObject: TDashboardFilter;
  refresh: boolean;
  onboarding: string | null;
  onboardingFinished: string[];
  onboardingSkipped: boolean;
  savedDashboardFilters: TSavedDashboardFilter[];
  exportBulkCSVLoading: boolean;
};

export const dashboardInitialState: DashboardReduxState = {
  dashboardData: {},
  dashboardBodyData: {},
  dashboardInitialized: false,
  dashboardDataLoading: false,
  dashboardBodyDataLoading: false,
  dashboardExternalDataLoading: false,
  dashboardFiltersLoading: false,
  dashboardDataShortKey: "",
  dashboardFilters: {},
  dashboardDataDirty: false,
  dashboardFieldParam: "",
  dashboardPerformanceParam: "",
  dashboardGranularity: "",
  dashboardAggType: "count_assessment",
  dashboardAssessmentTypesParam: [1, 2],
  dashboardBodyPartViewParam: "",
  dashboardBodyPartCalculateParam: "",
  filterObject: EMPTY_FILTER,
  refresh: false,
  onboarding: null,
  onboardingFinished: [],
  onboardingSkipped: false,
  savedDashboardFilters: [],
  exportBulkCSVLoading: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    setDashboardAggregationParams: (
      state,
      action: PayloadAction<{
        dashboardFieldParam: string | null;
        dashboardPerformanceParam: string | null;
        dashboardGranularity: string | null;
        dashboardAggType: string | null;
      }>,
    ) => {
      let fieldParam = action.payload.dashboardFieldParam;
      let performanceParam = action.payload.dashboardPerformanceParam;
      let granularityParam = action.payload.dashboardGranularity;
      let aggType = action.payload.dashboardAggType;
      if (fieldParam === null) {
        fieldParam = state.dashboardFieldParam;
      }
      if (performanceParam === null) {
        performanceParam = state.dashboardPerformanceParam;
      }
      if (granularityParam === null) {
        granularityParam = state.dashboardGranularity;
      }
      if (aggType === null) {
        aggType = state.dashboardAggType;
      }
      state.dashboardFieldParam = fieldParam;
      state.dashboardPerformanceParam = performanceParam;
      state.dashboardGranularity = granularityParam;
      state.dashboardAggType = aggType;
    },
    setDashboardBodyPartAggregationParams: (
      state,
      action: PayloadAction<{
        dashboardAssessmentTypesParam: number[] | null;
        dashboardBodyPartCalculateParam: number | string | null;
      }>,
    ) => {
      let assessmentTypesParam = action.payload.dashboardAssessmentTypesParam;
      let bodyPartCalculateParam =
        action.payload.dashboardBodyPartCalculateParam;
      if (assessmentTypesParam === null) {
        assessmentTypesParam = state.dashboardAssessmentTypesParam;
      }
      if (bodyPartCalculateParam === null) {
        bodyPartCalculateParam = state.dashboardBodyPartCalculateParam;
      }
      state.dashboardAssessmentTypesParam = assessmentTypesParam;
      state.dashboardBodyPartCalculateParam = bodyPartCalculateParam;
    },
    setDashboardDataShortKey: (
      state,
      action: PayloadAction<{ shortKey: string }>,
    ) => {
      state.dashboardDataShortKey = action.payload.shortKey;
      state.dashboardDataLoading = false;
    },
    setDashboardOnboarding: (
      state,
      action: PayloadAction<{ value: string | null }>,
    ) => {
      state.onboarding = action.payload.value;
    },
    setDashboardOnboardingSkipped: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.onboardingSkipped = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardDataRequest.pending, (state) => {
      state.dashboardDataLoading = true;
    });
    builder.addCase(getDashboardDataRequest.fulfilled, (state, action) => {
      if (action.payload.dashboardData) {
        state.dashboardData = action.payload.dashboardData;
        state.dashboardInitialized = true;
      }
      state.dashboardDataLoading = false;
    });
    builder.addCase(getDashboardDataRequest.rejected, (state) => {
      state.dashboardDataLoading = false;
    });
    builder.addCase(getExternalDashboardDataRequest.pending, (state) => {
      state.dashboardDataShortKey = "";
      state.dashboardExternalDataLoading = true;
    });
    builder.addCase(
      getExternalDashboardDataRequest.fulfilled,
      (state, action) => {
        if (action.payload.shortKey === null) {
          state.dashboardDataShortKey = "";
        } else if (action.payload.shortKey) {
          state.dashboardDataShortKey = action.payload.shortKey;
        }
        state.dashboardExternalDataLoading = false;
      },
    );
    builder.addCase(getExternalDashboardDataRequest.rejected, (state) => {
      state.dashboardExternalDataLoading = false;
    });
    builder.addCase(getDashboardBodyPartDataRequest.pending, (state) => {
      state.dashboardBodyDataLoading = true;
    });
    builder.addCase(
      getDashboardBodyPartDataRequest.fulfilled,
      (state, action) => {
        if (action.payload.dashboardBodyData) {
          state.dashboardBodyData = action.payload.dashboardBodyData;
        }
        state.dashboardBodyDataLoading = false;
      },
    );
    builder.addCase(getDashboardBodyPartDataRequest.rejected, (state) => {
      state.dashboardBodyDataLoading = false;
    });
    builder.addCase(getDashboardFiltersRequest.pending, (state) => {
      state.dashboardFiltersLoading = true;
    });
    builder.addCase(getDashboardFiltersRequest.fulfilled, (state, action) => {
      const metadataOptionList = sortMetadataArray(
        action.payload.dashboardFilters.metadataOptionList,
      );
      state.dashboardFilters = {
        ...action.payload.dashboardFilters,
        metadataOptionList,
      };
      state.dashboardFiltersLoading = false;
    });
    builder.addCase(getDashboardFiltersRequest.rejected, (state) => {
      state.dashboardFiltersLoading = false;
    });
    builder.addCase(setDashboardFilterField.pending, (state, action) => {
      state.filterObject[action.meta.arg.field as keyof TDashboardFilter] =
        action.meta.arg.value;
      state.dashboardDataLoading = true;
    });
    builder.addCase(setDashboardFilterFields.pending, (state, action) => {
      state.filterObject = action.meta.arg.filterObject;
      state.dashboardDataLoading = true;
    });
    builder.addCase(clearDashboardFilters.pending, (state) => {
      state.filterObject = EMPTY_FILTER;
      state.dashboardDataLoading = true;
    });
    builder.addCase(
      setDashboardOnboardingFinished.fulfilled,
      (state, action) => {
        state.onboardingFinished = action.payload.onboardingFinished;
      },
    );
    builder.addCase(saveDashboardFilter.fulfilled, (state, action) => {
      state.savedDashboardFilters = action.payload.savedDashboardFilters;
    });
    builder.addCase(deleteDashboardFilter.fulfilled, (state, action) => {
      state.savedDashboardFilters = action.payload.savedDashboardFilters;
    });
    builder.addCase(getDashboardDataExternal.pending, (state) => {
      state.dashboardDataLoading = true;
    });
    builder.addCase(getDashboardDataExternal.fulfilled, (state, action) => {
      const {
        dashboardData,
        dashboardBodyData,
        dashboardFilters,
        filterObject,
      } = action.payload;
      if (dashboardData) {
        state.dashboardData = dashboardData;
      }
      if (dashboardBodyData) {
        state.dashboardBodyData = dashboardBodyData;
      }
      if (dashboardFilters) {
        const metadataOptionList = sortMetadataArray(
          dashboardFilters.metadataOptionList,
        );
        state.dashboardFilters = {
          ...dashboardFilters,
          metadataOptionList,
        };
      }
      if (filterObject) {
        state.filterObject = filterObject;
      }
      state.dashboardInitialized = true;
      state.dashboardDataLoading = false;
    });
    builder.addCase(getDashboardDataExternal.rejected, (state) => {
      state.dashboardDataLoading = false;
    });
    builder.addCase(exportBulkCSVRequest.pending, (state) => {
      state.exportBulkCSVLoading = true;
    });
    builder.addCase(exportBulkCSVRequest.fulfilled, (state) => {
      state.exportBulkCSVLoading = false;
    });
    builder.addCase(exportBulkCSVRequest.rejected, (state) => {
      state.exportBulkCSVLoading = false;
    });
  },
});

export const {
  setDashboardAggregationParams,
  setDashboardBodyPartAggregationParams,
  setDashboardDataShortKey,
  setDashboardOnboarding,
  setDashboardOnboardingSkipped,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
