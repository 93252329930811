import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import moment from "moment-timezone";

import {
  cognitoAuthUser,
  cognitoRegisterUser,
  cognitoChangePasswordWeb,
  cognitoRefreshTokenWeb,
  cognitoSendVerificationcode,
  cognitoSignoutUser,
} from "@tumeke/tumekejs";
import {
  initializeVirgil,
  isVirgilInitialized,
  logoutVirgil,
  createNewUserVirgil,
  joinGroup,
  decryptAESKeys,
  changePassword,
  restorePrivateKey,
  initiateResetPasswordFlow,
  confirmPasswordReset,
} from "@tumeke/tumekejs/lib/auth";
import { TumekeJSModule, EAdapterNames, Config } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { Mixpanel } from "@kernel-helpers/Mixpanel";
import {
  loginUser as loginUserApi,
  createUser as createUserApi,
  createCompany as createCompanyApi,
  joinCompanyRequest as joinCompanyRequestApi,
  getCompanyById as getCompanyByIdApi,
  checkLoginStatus as checkLoginStatusApi,
  deleteTempPassword as deleteTempPasswordApi,
  checkAccountStatus as checkAccountStatusApi,
  checkCompanyUserLimit as checkCompanyUserLimitApi,
  updateCompanySettingsHelper as updateCompanySettingsApi,
  saveUserData as saveUserDataApi,
  getUserData as getUserDataApi,
  checkUserMfa as checkUserMfaApi,
} from "@kernel-helpers/DatabaseHelpers";
import {
  setMultifactorStatus,
  loginUserSuccess,
  loginUserError,
  virgilInitError,
  cancelAskPassword,
  enterPasswordError,
  registerUserStatus,
  increaseCompanyUserCount,
  sortMetadataObject,
  ERegisterStatus,
} from "@kernel-store/auth/slice";
import {
  acceptUserIntoCompanyRequest,
  createNewGroupRequest,
  addUserToGroupRequest,
} from "@kernel-store/adminProfile/thunk";
import { removeNotificationListener } from "@kernel-store/notifications/thunk";
import { removeVideoListeners } from "@kernel-store/videos/thunk";
import { ReduxState } from "@kernel-store/reducers";
import { handleVideoError as handleError } from "@kernel-helpers/ErrorHandle";

const EXPIRE_TIME_MINS = 5;

const loadingData = {
  decryptingKeys: false,
};

export type TRegisterPayload = {
  name: string;
  email: string;
  password: string;
  companyName: string;
  isCreatingNewOrg: boolean;
  companyID?: number;
  consultancy: boolean;
  adminAction: boolean;
  isCreatingNewGroup?: string;
  groupIdOrName?: string | number;
  isSendEmail?: boolean;
  emailTemplate?: string;
  limitType?: number;
  assessmentLimit?: number;
  meterStartDate?: string | null;
  accessMetadata?: any;
  accessUsers?: any;
};

export const virgilIdGenerator = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let str = "";
  for (let i = 0; i < 28; i += 1) {
    str += chars[Math.floor(Math.random() * 28)];
  }
  return str;
};

function hasNumber(str: string) {
  return /\d/g.test(str);
}

function hasLetter(str: string) {
  return /[a-zA-Z]/g.test(str);
}

export async function aliasMixpanel(email: string) {
  /* Mixpanel user creation */
  try {
    Mixpanel.alias(email);
  } catch (error) {
    console.log("MIXPANEL error: ", error);
  }
}

export async function initializeMixpanel(email: string) {
  /* Mixpanel identification */
  try {
    // await Mixpanel.alias(email);
    await Mixpanel.identify(email);
    await Mixpanel.people.set({
      $email: email,
      // $last_login: new Date().toDateString("en-US", {
      //  timeZone: "America/Los_Angeles",
      // }),
      $last_login: new Date().toDateString(),
      $user_type: [
        "znoland3@gmail.com",
        "diwakar.ganesan@gmail.com",
        "rnoland97@gmail.com",
      ].includes(email)
        ? "internal"
        : "external",
    });
  } catch (error) {
    console.log("MIXPANEL error: ", error);
  }
}

export async function resetMixpanel() {
  // await Mixpanel.reset(email);
  await Mixpanel.reset();
}

const cognitologinWithEmailPasswordAsync = async (
  email: string,
  password: string,
  onlyCheck = false,
  mfaWithEmail = false,
) =>
  cognitoAuthUser(email, password, onlyCheck, mfaWithEmail)
    .then((authUser) => authUser)
    .catch((error) => error);

const cognitoSendSMSAsync = async (email: string, code: string): Promise<any> =>
  cognitoSendVerificationcode(email, code)
    .then((msg) => msg)
    .catch((error) => error);

const registerWithEmailPasswordAsync = async (
  email: string,
  password: string,
  virgilId: string,
) =>
  cognitoRegisterUser(email, password, virgilId)
    .then((authUser) => authUser)
    .catch((error) => error);

const cognitoChangePasswordAsync = async (
  user: any,
  oldPass: string,
  newPassword: string,
) =>
  cognitoChangePasswordWeb(user, oldPass, newPassword).then(
    (authUser) => authUser,
  );

const checkLoginStatusHelper = async () => {
  try {
    const cognitoUser = await cognitoRefreshTokenWeb();
    const idToken = cognitoUser.getIdToken().getJwtToken();
    const token = {
      token: idToken,
      expirationDate: moment().add(EXPIRE_TIME_MINS, "minutes").toString(),
    };
    const stogareAdapter = TumekeJSModule.get(
      EAdapterNames.Storage,
    ) as IStorageAdapter;
    await stogareAdapter.setItem("id_token", JSON.stringify(token));
    await checkLoginStatusApi();
    return true;
  } catch (e) {
    console.log(e);
    console.log("[CHECK] caught error!");
  }
  return false;
};

export const setDecryptedAESKeysRequest = createAsyncThunk<
  { aesKeys: { [key: string]: string } },
  { callback?: any; password?: string },
  { state: ReduxState }
>(
  "auth/setDecryptedAESKeysRequest",
  async ({ callback, password }, { getState, dispatch }) => {
    // TODO: Clean up this logic
    const state = getState();
    if (loadingData.decryptingKeys) {
      throw new Error("Decrypting in process");
    }
    if (!state.authUser.user) {
      throw new Error("Empty user");
    }
    if (state.authUser.user?.decryptedAESKeys) {
      throw new Error("Keys exists");
    }
    loadingData.decryptingKeys = true;
    if (state.authUser.user.isSSO || state.authUser.company.virgil_off) {
      console.log("Company AES", state.authUser.company.aes_key);
      if (callback) {
        console.log("Calling callback");
        callback();
      }
      loadingData.decryptingKeys = false;
      return { aesKeys: state.authUser.company.aes_key };
    }
    if (isVirgilInitialized()) {
      try {
        const decryptedKeys = (await decryptAESKeys(
          state.authUser.user,
          state.authUser.company,
        )) as unknown as any;
        if (callback) {
          console.log("Calling callback");
          callback();
        }
        loadingData.decryptingKeys = false;
        return { aesKeys: decryptedKeys };
      } catch (e) {
        dispatch(virgilInitError());
        loadingData.decryptingKeys = false;
        throw e;
      }
    }
    try {
      await initializeVirgil(state.authUser.user.virgil_id);
      await restorePrivateKey(password || "");
      await joinGroup(state.authUser.user, state.authUser.company);
    } catch (e) {
      console.log(`DECRYPT KEYS VIRGIL ERROR: ${e}`);
      dispatch(virgilInitError());
      loadingData.decryptingKeys = false;
      throw e;
    }
    try {
      const decryptedKeys = (await decryptAESKeys(
        state.authUser.user,
        state.authUser.company,
      )) as unknown as any;
      dispatch(cancelAskPassword());
      if (callback) {
        console.log("Calling callback");
        callback();
      }
      loadingData.decryptingKeys = false;
      return { aesKeys: decryptedKeys };
    } catch (error) {
      console.log(error);
      dispatch(enterPasswordError({ errorMsg: "Password incorrect" }));
      loadingData.decryptingKeys = false;
      throw error;
    }
  },
);

/* Deprecated */
export const enteredPasswordRequest = createAsyncThunk<
  { aesKeys: { [key: string]: string } },
  { password: string },
  { state: ReduxState }
>(
  "auth/enteredPasswordRequest",
  async ({ password }, { getState, dispatch }) => {
    const state = getState();
    try {
      await initializeVirgil(state.authUser.user.virgil_id);
      await restorePrivateKey(password);
      await joinGroup(state.authUser.user, state.authUser.company);

      const decryptedKeys = (await decryptAESKeys(
        state.authUser.user,
        state.authUser.company,
      )) as unknown as any;
      dispatch(cancelAskPassword());
      return { aesKeys: decryptedKeys };
    } catch (error) {
      console.log(error);
      dispatch(enterPasswordError({ errorMsg: "Password incorrect" }));
      throw error;
    }
  },
);

export const setCompanyData = createAsyncThunk<
  void,
  { [key: string]: any },
  { state: ReduxState }
>("auth/setCompanyData", async (_, { getState }) => {
  const state = getState();
  const companyObj = state.authUser.company;
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  await stogareAdapter.setItem("companyObj", JSON.stringify(companyObj));
});

export const loginServerHelper = createAsyncThunk<
  { user: any; company: any },
  {
    cognitoUser: any;
    user: {
      email: string;
      password: string;
      isSSO?: boolean;
      clientId?: string;
      isDemo?: boolean;
    };
    history?: { push: (url: string) => void };
  },
  { state: ReduxState }
>(
  "auth/loginServerHelper",
  async (
    { cognitoUser, user: { email, password, isSSO, clientId }, history },
    { dispatch },
  ) => {
    console.log("IN LOGIN SERVER");
    let user: any = null;
    let company: any = null;
    let isError = false;
    try {
      const idToken = cognitoUser.getIdToken().getJwtToken();
      const token = {
        token: idToken,
        expirationDate: moment().add(EXPIRE_TIME_MINS, "minutes").toString(),
      };
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      await stogareAdapter.setItem("id_token", JSON.stringify(token));
      user = await loginUserApi();

      if (user.role === "none" || user.role === "rejected") {
        throw new Error("User not authorized to access this website.");
      }
      if (!user.has_paid_deal && !user.trial_active) {
        if (history) {
          history.push("/user/trial-expired");
        }
        throw new Error(
          `Your trial has expired. To move on to a paid plan please contact: ${Config.SUPPORT_EMAIL}`,
        );
      }
      company = await getCompanyByIdApi(user.company_id);
      const metadataSorted = sortMetadataObject(company.metadata);
      company = { ...company, metadata: metadataSorted };

      const userCookie = user.isDemo ? "demoUserObj" : "userObj";
      user.isSSO = isSSO;
      if (clientId) user.clientId = clientId;
      await stogareAdapter.setItem(userCookie, JSON.stringify(user));
      await stogareAdapter.setItem("companyObj", JSON.stringify(company));
      dispatch(loginUserSuccess({ user, company }));
      dispatch(setDecryptedAESKeysRequest({ callback: () => null, password }));
      try {
        await initializeMixpanel(email);
        // eslint-disable-next-line no-empty
      } catch {}
    } catch (e: any) {
      if (String(e?.response?.data?.message).match(/multi-factor/i)) {
        dispatch(
          setMultifactorStatus({
            status: "OFF",
            destination: "",
          }),
        );
        if (history) {
          history.push("/user/multifactor");
        }
        return { user: null, company: null };
      }
      isError = true;
    }
    if (isError) {
      throw new Error("Login error");
    }
    if (history) {
      history.push("/app/home/home-nav");
    }
    return { user, company };
  },
);

export const loginUser = createAsyncThunk<
  void,
  {
    user: { email: string; password: string; mfaWithEmail?: boolean };
    history?: { push: any };
    callback?: () => void;
  },
  { state: ReduxState }
>(
  "auth/loginUser",
  async (
    { user: { email, password, mfaWithEmail }, history, callback },
    { dispatch },
  ) => {
    try {
      // diwgan32: Commenting out on 10/14/24 to experiment with consequences of not
      // calling checkUserMfa on every login
      // await checkUserMfaApi(email);

      const cognitoUser = (await cognitologinWithEmailPasswordAsync(
        email,
        password,
        false,
        mfaWithEmail,
      )) as unknown as any;
      if (cognitoUser.message === "mfa") {
        if (history) {
          history.push("/user/multifactor");
        }
        dispatch(
          setMultifactorStatus({
            status: "SENT_CODE",
            destination: cognitoUser.destination,
          }),
        );
        return;
      }
      if (cognitoUser.message) {
        dispatch(loginUserError({ message: cognitoUser.message }));
        return;
      }
      await dispatch(
        loginServerHelper({
          cognitoUser,
          user: { email, password },
          history,
        }),
      ).unwrap();
      if (callback) {
        callback();
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      dispatch(loginUserError({ message: "Login error" }));
    }
  },
);

export const checkUserMfa = createAsyncThunk<
  void,
  { email: string },
  { state: ReduxState }
>("auth/checkUserMfa", async ({ email }, { dispatch }) => {
  try {
    await checkUserMfaApi(email);
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
    dispatch(loginUserError({ message: "Login error" }));
  }
});

export const enterMultifactorCode = createAsyncThunk<
  void,
  {
    code: string;
    email: string;
    password: string;
    history: { push: any };
  },
  { state: ReduxState }
>(
  "auth/enterMultifactorCode",
  async ({ code, email, password, history }, { dispatch }) => {
    try {
      const cognitoUser = (await cognitoSendSMSAsync(
        email,
        code,
      )) as unknown as any;
      if (cognitoUser.code === "CodeMismatchException") {
        dispatch(loginUserError({ message: "Invalid code" }));
        return;
      }
      if (cognitoUser.message) {
        dispatch(loginUserError({ message: cognitoUser.message }));
        return;
      }
      await dispatch(
        loginServerHelper({
          cognitoUser,
          user: { email, password },
          history,
        }),
      ).unwrap();
    } catch (e) {
      console.log("[MFA] Error: ", e);
      dispatch(loginUserError({ message: "Login error" }));
    }
  },
);

export const registerUser = createAsyncThunk<
  { user?: any; company?: any },
  {
    user: TRegisterPayload;
    history: { push: any };
  },
  { state: ReduxState }
>(
  "auth/registerUser",
  async (
    {
      user: {
        name,
        email,
        password,
        companyName,
        isCreatingNewOrg,
        companyID,
        consultancy,
        adminAction,
        isCreatingNewGroup,
        groupIdOrName,
        isSendEmail,
        emailTemplate,
        accessMetadata,
        accessUsers,
      },
      history,
    },
    { dispatch },
  ) => {
    let company = null;
    let user = null;
    try {
      // COGNITO USER REGISTER
      const virgilId = virgilIdGenerator();
      console.log(`Generated virgil id: ${virgilId}`);
      dispatch(registerUserStatus({ status: ERegisterStatus.CheckCompany }));
      // Check only for company admin
      if (adminAction && companyID) {
        const companyUserLimit = await checkCompanyUserLimitApi(
          companyID,
          companyName,
        );
        if (companyUserLimit?.message === "Limit") {
          throw new Error("Company user limit exceeded");
        }
      }
      dispatch(registerUserStatus({ status: ERegisterStatus.CongitoRegister }));
      let registeredUser: any = {};
      if (!adminAction) {
        registeredUser = (await registerWithEmailPasswordAsync(
          email,
          password,
          virgilId,
        )) as unknown as any;
        console.log("registered user", registeredUser);
      }
      if (registeredUser.message) {
        throw new Error(registeredUser.message);
      }
      if (!adminAction) {
        dispatch(registerUserStatus({ status: ERegisterStatus.CongitoLogin }));
        const cognitoUser = (await cognitologinWithEmailPasswordAsync(
          email,
          password,
        )) as unknown as any;
        dispatch(registerUserStatus({ status: ERegisterStatus.VirginInit }));
        const idToken = cognitoUser.getIdToken().getJwtToken();
        const token = {
          token: idToken,
          expirationDate: moment().add(EXPIRE_TIME_MINS, "minutes").toString(),
        };
        const stogareAdapter = TumekeJSModule.get(
          EAdapterNames.Storage,
        ) as IStorageAdapter;
        await stogareAdapter.setItem("id_token", JSON.stringify(token));
      }
      await initializeVirgil(virgilId);
      dispatch(
        registerUserStatus({ status: ERegisterStatus.VirgilCreateUser }),
      );
      await createNewUserVirgil(password);

      // CREATE USER DOC
      dispatch(registerUserStatus({ status: ERegisterStatus.CreateUser }));
      const createdUser = await createUserApi(
        {
          name,
          email,
          password,
          isSendEmail,
          emailTemplate,
        },
        virgilId,
        companyID,
        adminAction,
      );
      const userId = createdUser.id;

      if (isCreatingNewOrg) {
        dispatch(registerUserStatus({ status: ERegisterStatus.CreateCompany }));
        company = await createCompanyApi(
          virgilId,
          companyName,
          consultancy,
          userId,
          adminAction,
          user,
        );
        const stogareAdapter = TumekeJSModule.get(
          EAdapterNames.Storage,
        ) as IStorageAdapter;
        await stogareAdapter.setItem("companyObj", JSON.stringify(company));
        console.log(`COMPANY ID: ${company.id}`);
      } else {
        console.log("Requesting company join");
        dispatch(registerUserStatus({ status: ERegisterStatus.JoinCompany }));
        await joinCompanyRequestApi(
          companyName,
          companyID,
          userId,
          adminAction,
        );
      }
      if (adminAction) {
        if (companyID) {
          dispatch(increaseCompanyUserCount({ count: 1 }));
        }
        if (isCreatingNewGroup && groupIdOrName) {
          dispatch(
            registerUserStatus({ status: ERegisterStatus.AddingToGroup }),
          );
          dispatch(
            acceptUserIntoCompanyRequest({
              user: createdUser,
              sendEmail: false,
            }),
          );
          if (isCreatingNewGroup === "true") {
            dispatch(
              createNewGroupRequest({
                users: [createdUser],
                groupName: String(groupIdOrName),
                accessMetadata,
                accessUsers,
              }),
            );
          } else {
            dispatch(
              addUserToGroupRequest({
                user: createdUser,
                groupId: parseInt(String(groupIdOrName), 10),
              }),
            );
          }
        }
        return { user, company };
      }
      console.log("logging in....");
      user = await loginUserApi();
      console.log("Got user obj");
      const stogareAdapter = TumekeJSModule.get(
        EAdapterNames.Storage,
      ) as IStorageAdapter;
      await stogareAdapter.setItem("userObj", JSON.stringify(user));
      console.log("Stored user obj");
      history.push("/");
      try {
        await aliasMixpanel(email);
        await initializeMixpanel(email);
        // eslint-disable-next-line no-empty
      } catch {}
      if (isCreatingNewOrg) {
        return { user, company };
      }
      return { user };
    } catch (error: any) {
      if (error.response) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Registration error. Please try again");
      }
    }
  },
);

export const logoutUser = createAsyncThunk<
  void,
  { history: { push: any } },
  { state: ReduxState }
>("auth/logoutUser", async ({ history }, { dispatch }) => {
  console.log("logout");
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  try {
    const userObj: any = await stogareAdapter.getItem("userObj");
    if (!isVirgilInitialized() && userObj) {
      await initializeVirgil(userObj.virgil_id);
    }
    await logoutVirgil();

    dispatch(removeVideoListeners({}));
    dispatch(removeNotificationListener());

    await resetMixpanel();
    cognitoSignoutUser();
  } catch (error) {
    console.log("Logout error", error);
  }
  await stogareAdapter.removeItem("userObj");
  await stogareAdapter.removeItem("companyObj");
  await stogareAdapter.removeItem("id_token");

  // Sometimes history not passed in
  if (history) {
    history.push("/user/login");
  }
});

/* Deprecated */
export const checkPassword = createAsyncThunk<
  void,
  { history: { push: any } },
  { state: ReduxState }
>("auth/checkPassword", async ({ history }, { dispatch }) => {
  console.log("[CHECK] Entered check password thunk");
  try {
    const ret = (await checkLoginStatusHelper()) as unknown as any;
    if (!ret) {
      throw new Error("Inactive error");
    }
  } catch (e) {
    console.log("[CHECK] BAD");
    console.log(`[CHECK] ${JSON.stringify(e)}`);
    dispatch(logoutUser({ history }));
    NotificationManager.warning(
      "Logout",
      "Logged out due to inactivity. Please log back in.",
      3000,
      null,
      null,
      "",
    );
  }
});

export const forgotPassword = createAsyncThunk<
  string,
  { email: string },
  { state: ReduxState }
>("auth/forgotPassword", async ({ email }) => {
  let ret = "";
  try {
    ret = (await initiateResetPasswordFlow(email)) as unknown as any;
    if (ret === "INITIATED_RESET" || ret === "VIRGIL_OFF") {
      NotificationManager.success(
        "Please check your email.",
        "Forgot Password Success",
        3000,
        null,
        null,
        "",
      );
    } else if (ret === "ERROR") {
      NotificationManager.error(
        "Please try again later.",
        "Forgot Password Error",
        3000,
        null,
        null,
        "",
      );
    }
  } catch (e: any) {
    handleError(e);
    throw e;
  }
  return ret;
});

export const forgotPasswordConfirm = createAsyncThunk<
  string,
  {
    email: string;
    forgotUserConfirmData: {
      newPassword: string;
      code: string;
      shouldResetAccount: boolean;
    };
    history: { push: (url: string) => void };
  },
  { state: ReduxState }
>(
  "auth/forgotPasswordConfirm",
  async ({
    email,
    forgotUserConfirmData: { newPassword, code, shouldResetAccount },
    history,
  }) => {
    try {
      await confirmPasswordReset(email, code, newPassword, shouldResetAccount);
    } catch (e) {
      handleError(e);
      throw e;
    }
    NotificationManager.success(
      `Successfully reset password`,
      "Password reset success",
      5000,
      null,
      null,
      "filled",
    );
    history.push("/user/login");
    return "INITIAL";
  },
);

export const resetPassword = createAsyncThunk<
  boolean,
  {
    oldPassword: string;
    newPassword1: string;
    newPassword2: string;
  },
  { state: ReduxState }
>(
  "auth/resetPassword",
  async (
    { oldPassword, newPassword1, newPassword2 },
    { getState, dispatch },
  ) => {
    console.log(`oldPassword: ${oldPassword}`);
    console.log(`newPassword: ${newPassword1}`);
    console.log("In change password thunk");
    const state = getState();
    const { company } = state.authUser;

    if (newPassword1 !== newPassword2) {
      NotificationManager.warning(
        "New passwords don't match",
        "Reset Password Error",
        3000,
        null,
        null,
        "",
      );
      throw new Error("New passwords don't match");
    }

    if (newPassword1.length < 8) {
      NotificationManager.warning(
        "New password must be at least 8 characters",
        "Reset Password Error",
        3000,
        null,
        null,
        "",
      );
      throw new Error("New password must be at least 8 characters");
    }

    if (!hasNumber(newPassword1) || !hasLetter(newPassword1)) {
      NotificationManager.warning(
        "New password must have mix of letters and numbers",
        "Reset Password Error",
        3000,
        null,
        null,
        "",
      );
      throw new Error("New password must be at least 8 characters");
    }

    if (!company.virgil_off) {
      if (!isVirgilInitialized()) {
        try {
          await initializeVirgil(state.authUser.user.virgil_id);
          await restorePrivateKey(oldPassword);
          await joinGroup(state.authUser.user, state.authUser.company);
        } catch (e) {
          dispatch(virgilInitError());
        }
      }
      try {
        console.log("Changing password");
        await changePassword(oldPassword, newPassword1);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
        // If this error happens then there was a problem with old password
        NotificationManager.warning(
          "Old password wrong",
          "Reset Password Error",
          3000,
          null,
          null,
          "",
        );
        throw new Error("Old password wrong");
      }
    }

    try {
      console.log("Changing password.... cognito");
      await cognitoChangePasswordAsync(
        state.authUser.user.email,
        oldPassword,
        newPassword1,
      );
    } catch (err) {
      console.log(err);
      NotificationManager.warning(
        "Password set error",
        "Reset Password Error",
        3000,
        null,
        null,
        "",
      );
      if (!company.virgil_off) {
        await changePassword(newPassword1, oldPassword);
      }
      throw new Error("Password set error");
    }
    try {
      await deleteTempPasswordApi(state.authUser.user.id);
    } catch (e) {
      console.log("ERROR", e);
      // do nothing
    }
    NotificationManager.success(
      `Successfully updated`,
      "Password",
      3000,
      null,
      null,
      "filled",
    );
    return true;
  },
);

export const checkTrialStatus = createAsyncThunk<
  void,
  { email: string; history: { push: (url: string) => void } },
  { state: ReduxState }
>("auth/checkTrialStatus", async ({ email, history }, { dispatch }) => {
  const accountStatus = await checkAccountStatusApi(email);
  if (!accountStatus.has_paid_deal && !accountStatus.trial_active) {
    dispatch(
      loginUserError({
        message:
          "Your trial has expired. To move on to a paid plan please contact: riley@tumeke.io",
      }),
    );
    // dispatch(logoutUser({}));
    if (history) {
      history.push("/user/trial-expired");
    }
    return;
  }
  console.log("[ACCOUNT_STATUS]", accountStatus);
});

export const updateCompanySettings = createAsyncThunk<
  any | null,
  { key: string; value: string },
  { state: ReduxState }
>("auth/updateCompanySettings", async ({ key, value }, { getState }) => {
  try {
    const state = getState();
    const { company_id: companyId } = state.authUser.user;
    const companyData = await updateCompanySettingsApi(companyId, key, value);
    NotificationManager.success(
      "Settings updated successfully",
      "Settings updated",
      3000,
      null,
      null,
      "",
    );
    return companyData;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});

export const updateUserSettings = createAsyncThunk<
  any,
  { [key: string]: string },
  { state: ReduxState }
>("auth/updateUserSettings", async (values, { getState }) => {
  try {
    const state = getState();
    const { user } = state.authUser;
    const userData = await saveUserDataApi(
      user.id,
      values,
      ["superadmin"].includes(user.role),
    );
    const updatedUser = { ...user, ...userData };
    const stogareAdapter = TumekeJSModule.get(
      EAdapterNames.Storage,
    ) as IStorageAdapter;
    await stogareAdapter.setItem("userObj", JSON.stringify(updatedUser));

    NotificationManager.success(
      "Settings updated successfully",
      "Settings updated",
      3000,
      null,
      null,
      "",
    );
    return userData;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});

export const updateUser = createAsyncThunk<
  any,
  undefined,
  { state: ReduxState }
>("auth/updateUser", async (_, { getState }) => {
  try {
    const state = getState();
    const { user } = state.authUser;
    const userData = await getUserDataApi(user.id);
    const updatedUser = { ...user, ...userData };
    const stogareAdapter = TumekeJSModule.get(
      EAdapterNames.Storage,
    ) as IStorageAdapter;
    await stogareAdapter.setItem("userObj", JSON.stringify(updatedUser));
    return userData;
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});
